<template>
    <div
        class="tw-flex tw-gap-2.5 tw-py-3 tw-pl-4 tw-pr-3 tw-bg-white tw-rounded-sm tw-w-fit md:tw-max-w-md tw-drop-shadow-2xl tw-border-l-4"
        :class="borderColor"
        aria-live="polite"
        aria-atomic="true"
        :role="type === 'error' ? 'alert' : 'status'"
    >
        <div class="tw-w-[18px] tw-h-[18px] tw-mt-[4px]">
            <ErrorIcon v-if="type === 'error'" />
            <InfoIcon v-if="type === 'info'" />
            <SuccessIcon v-if="type === 'success'" />
            <WarningIcon v-if="type === 'warning'" />
        </div>
        <div>
            <AppText
                class=""
                size="small"
            >
                {{ title }}
            </AppText>
            <AppText
                class="tw-place-self-center"
                tracking="wide"
                size="xsmall"
                tone="interface"
            >
                {{ message }}
            </AppText>
        </div>
        <button
            class="tw-h-fit tw-w-fit"
            aria-label="Remove notification"
            tabindex="0"
            @click="removeNotification"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                class="tw-ml-2 tw-mt-0.5"
                aria-hidden="true"
            ><path
                class="tw-fill-interface-600"
                d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
            /></svg>
        </button>
    </div>
</template>

<script>
import AppText from '@app/atoms/AppText.vue';
import ErrorIcon from "@icons/ErrorIcon.vue";
import InfoIcon from "@icons/InfoIcon.vue";
import SuccessIcon from "@icons/SuccessIcon.vue";
import WarningIcon from "@icons/WarningIcon.vue";
import { mapStores } from 'pinia';
import { useNotificationStore } from '@stores/notificationStore';

export default {
    name: 'ToastNotification',
    components: {
        AppText,
        ErrorIcon,
        InfoIcon,
        SuccessIcon,
        WarningIcon
    },
    props: {
        id: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        message: {
            type: String,
            required: true
        },
        type: {
            type: String,
            default: 'info'
        }
    },
    computed: {
        ...mapStores(useNotificationStore),
        borderColor() {
            const borderMap = {
                info: 'tw-border-l-blue-600',
                success: 'tw-border-l-green-600',
                warning: 'tw-border-l-yellow-500',
                error: 'tw-border-l-red-800'
            };

            return borderMap[this.type];
        }
    },
    methods: {
        removeNotification() {
            this.notificationStore.removeNotification(this.id);
        }
    }
};
</script>
