<template>
    <div class="tw-fixed tw-z-30 tw-bottom-0 tw-left-0 tw-m-2 md:tw-m-8 tw-space-y-4">
        <TransitionGroup
            enter-from-class="tw-translate-y-20"
            enter-to-class="tw-translate-y-0"
            enter-active-class="tw-transition tw-duration-300 motion-reduce:tw-transition-none"
            leave-to-class="-tw-translate-y-10 tw-scale-y-0 tw-opacity-0"
            leave-active-class="tw-transition tw-duration-500 motion-reduce:tw-transition-none"
        >
            <template
                v-for="notification in notificationStore.notificationStack"
                :key="notification.id"
            >
                <ToastNotification
                    :id="notification.id"
                    :title="notification.title"
                    :message="notification.message"
                    :type="notification.type"
                />
            </template>
        </TransitionGroup>
    </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useNotificationStore } from '@stores/notificationStore';
import ToastNotification from '@notification/molecules/ToastNotification.vue';

export default {
    name: 'NotificationStack',
    components: {
        ToastNotification
    },
    computed: {
        ...mapStores(useNotificationStore)
    },
    beforeUnmount() {
        this.notificationStore.clearNotifications();
    }
};
</script>
